import type React from 'react';
import styled from 'styled-components';

import type { BoxProps } from './Box';
import { boxCss, spacingCss } from './Box';

export interface FlexProps extends BoxProps {
  children: React.ReactNode;
  direction?: 'row' | 'column';
  wrap?: string;
  justifyContent?:
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-start'
    | 'flex-end'
    | 'center';
  alignItems?: 'center' | 'stretch' | 'baseline' | 'flex-end' | 'flex-start';
  columnGap?: string;
  rowGap?: string;
  flex?: number | string;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'initial'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  align-items: ${({ alignItems }) => alignItems ?? 'stretch'};
  column-gap: ${({ columnGap }) => columnGap ?? '0'};
  row-gap: ${({ rowGap }) => rowGap ?? '0'};
  flex-wrap: ${({ wrap }) => wrap ?? 'nowrap'};
  flex: ${({ flex }) => flex ?? 'initial'};
  ${spacingCss};
  ${boxCss};
`;
